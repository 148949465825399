import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { notUndefinedFilter } from '@ev-portals/ev/frontend/util';
import { combineLatest, map, Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';
import { SelectedLocationService } from '../selected-location.service';

@Injectable({
  providedIn: 'root',
})
export class LocationGuard {
  #authenticationService = inject(AuthenticationService);
  #navigationService = inject(NavigationService);
  #selectedLocationService = inject(SelectedLocationService);
  #router = inject(Router);
  #destroyRef = inject(DestroyRef);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#checkSalesUserLocation(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#checkSalesUserLocation(state);
  }

  /**
   * We have various checks:
   * - If user is NOT logged in, hasSalesRole is undefined and selectedLocation is null (can bypass location guard)
   * - If user is logged in and doesnt have sales role, selectedLocation is null and must be null (can bypass location guard)
   * - If user is logged in and user has sales role. selectedLocation can be either null or not null.
   * - If user is logged in, has sales role and selected location is null => (cannot bypass location guard, we redirect to selected location route)
   * - If user is logged in, has sales role and selected location is not null => (can bypass location guard)
   */
  #checkSalesUserLocation(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([
      this.#authenticationService.isLoggedIn$,
      this.#authenticationService.hasSalesRole$,
      // we filter out undefined value (since we want to wait to backend call to finish)
      this.#selectedLocationService.selectedLocation$.pipe(notUndefinedFilter()),
    ]).pipe(
      map(([isLoggedIn, hasSalesRole, selectedLocation]) => {
        if (isLoggedIn && hasSalesRole && !selectedLocation) {
          this.#navigationService.storeOriginalDestination(state.url);
          return this.#router.parseUrl(this.#navigationService.getUrlByPageName('select-location'));
        }

        return true;
      }),
      takeUntilDestroyed(this.#destroyRef),
    );
  }
}

import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  AccountAddressItem,
  AccountsApiService,
  AvailableAddressesResponseDto,
  SelectedLocationDto,
} from '@ev-portals/cp/frontend/shared/api-client';
import { BehaviorSubject, filter, first, Observable, shareReplay, switchMap, tap } from 'rxjs';

import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class SelectedLocationService {
  #accountsApiService = inject(AccountsApiService);
  #authenticationService = inject(AuthenticationService);
  #destroyRef = inject(DestroyRef);

  // undefined is default value, null is when no location is selected
  #selectedLocationSubject = new BehaviorSubject<SelectedLocationDto | null | undefined>(undefined);
  selectedLocation$ = this.#selectedLocationSubject.asObservable();
  $selectedLocation = toSignal(this.selectedLocation$);

  availableSoldToAddresses$: Observable<AvailableAddressesResponseDto> =
    this.#authenticationService.isLoggedIn$.pipe(
      filter(Boolean),
      switchMap(() => this.#accountsApiService.getAvailableSoldToAddresses()),
      shareReplay(1),
    );

  constructor() {
    this.getSelectedLocationFromBackend();
  }

  private getSelectedLocationFromBackend(): void {
    this.#authenticationService.hasSalesRole$
      .pipe(
        tap(hasSalesRole => {
          // if no sales role it cannot have location selected
          if (!hasSalesRole) {
            this.#selectedLocationSubject.next(null);
          }
        }),
        filter(Boolean),
        switchMap(() => this.#accountsApiService.getSelectedLocation()),
        tap(selectedLocationFromDb => this.#selectedLocationSubject.next(selectedLocationFromDb)),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  public getAvailableSoldTosFromBackend(): Observable<AvailableAddressesResponseDto> {
    return this.#accountsApiService.getAvailableSoldToAddresses().pipe(first());
  }

  public getAvailableShipTosFromBackend(
    partialSelectedLocation: Partial<NewSelectedLocation>,
  ): Observable<AvailableAddressesResponseDto> {
    return this.#accountsApiService.getAvailableShipToAddresses({
      soldToKey: partialSelectedLocation.soldToKey as string,
    });
  }

  public getAvailableBillTosFromBackend(
    partialSelectedLocation: Partial<NewSelectedLocation>,
  ): Observable<AvailableAddressesResponseDto> {
    return this.#accountsApiService
      .getAvailableBillToAddresses({
        soldToKey: partialSelectedLocation.soldToKey as string,
        shipToKey: partialSelectedLocation.shipToKey as string,
      })
      .pipe(first());
  }

  public getAvailablePayersFromBackend(
    partialSelectedLocation: Partial<NewSelectedLocation>,
  ): Observable<AvailableAddressesResponseDto> {
    return this.#accountsApiService
      .getAvailablePayerAddresses({
        soldToKey: partialSelectedLocation.soldToKey as string,
        shipToKey: partialSelectedLocation.shipToKey as string,
        billToKey: partialSelectedLocation.billToKey as string,
      })
      .pipe(first());
  }

  public saveSelectedLocationInBackend(
    newSelectedLocation: NewSelectedLocation,
    includedAddresses: AccountAddressItem[],
  ): void {
    // we need to set this in the frontend cause we don't get back the selected location from the patch call and the get call comes to late for the location guard.
    this.#selectedLocationSubject.next({
      soldToKey: newSelectedLocation.soldToKey as string,
      shipToKey: newSelectedLocation.shipToKey as string,
      billToKey: newSelectedLocation.billToKey as string,
      payerKey: newSelectedLocation.payerKey as string,
      // sales areas are empty buy will get filled in the get call in the tap operator callback.
      salesAreas: [],
      includedAddresses,
    });

    this.#accountsApiService
      .patchSelectedLocation({
        body: {
          soldToKey: newSelectedLocation.soldToKey as string,
          shipToKey: newSelectedLocation.shipToKey as string,
          billToKey: newSelectedLocation.billToKey as string,
          payerKey: newSelectedLocation.payerKey as string,
          includedAddresses,
        },
      })
      .pipe(
        first(),
        tap(() => {
          // Reload the user after changing location
          this.#authenticationService.loadUser().subscribe();
          this.getSelectedLocationFromBackend();
        }),
      )
      .subscribe();
  }
}

export enum AddressType {
  SoldTo = 'soldToKey',
  ShipTo = 'shipToKey',
  BillTo = 'billToKey',
  Payer = 'payerKey',
}

export type NewSelectedLocation = Partial<Record<AddressType, string>>;

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { map, Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';
import { FederationService } from '../federation.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard {
  #federationServie = inject(FederationService);
  #authenticationService = inject(AuthenticationService);
  #navigationService = inject(NavigationService);
  #router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#checkUser(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#checkUser(state);
  }

  #checkUser(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.#authenticationService.user$.pipe(
      map(user => {
        if (!user) {
          // save current url in localStorage to redirect to it after login
          this.#navigationService.storeOriginalDestination(state.url);
          this.#federationServie.redirectToLogin();
          return false;
        }

        if (!user?.roles.length) {
          return this.#router.parseUrl(
            this.#navigationService.getUrlByPageName('error-no-role-assigned'),
          );
        }

        return true;
      }),
    );
  }
}

import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { map, Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class SalesRoleGuard {
  #authenticationService = inject(AuthenticationService);
  #router = inject(Router);
  #navigationService = inject(NavigationService);

  canActivate(): Observable<boolean | UrlTree> {
    return this.#checkSalesRole();
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.#checkSalesRole();
  }

  #checkSalesRole(): Observable<boolean | UrlTree> {
    return this.#authenticationService.hasSalesRole$.pipe(
      map(hasSalesRole => {
        if (!hasSalesRole) {
          return this.#router.parseUrl(this.#navigationService.getUrlByPageName('error-403'));
        }

        return true;
      }),
    );
  }
}

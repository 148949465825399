import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { Observable, of } from 'rxjs';

export function autoLoginGuard(): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> => {
    const navigationService = inject(NavigationService);
    const code = route.queryParamMap.get('code');

    // If we have a code query param, then the login page does the auto-login like before
    if (code) {
      // save current url in localStorage to redirect to it after login
      navigationService.storeOriginalDestination(state.url);

      return of(
        navigationService.getUrlTreeByPageName('login', {
          queryParams: route.queryParams,
        }),
      );
    }

    return of(true);
  };
}
